<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">店舗基本情報</h1>
        <banner-hint>
          営業届出確認書(以下、届出書)に基づいた営業時間やお店電話番号と言った店舗の営業に関する基本情報を入力します。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        class="pt-5 mb-5"
      >
        <v-card
          class="px-10 py-7"
          elevation="1"
        >
          <icon-info icon="alert" class="mb-10" :square="true">
            更新できない情報に関しては必要書類を確認の上、システム運営側にて変更します。<br />
            必要の際はお手数ですがお問い合わせページからご連絡ください。
          </icon-info>

          <v-form ref="form">
            <v-row no-gutters>
              <!-- 店舗名 -->
              <v-col cols="12">
                <v-text-field
                  v-model.trim="shopInfo.shop_name"
                  filled readonly
                  label="届出書記載の店舗名"
                  counter="50"
                  required
                  :rules="[valiRules.required, valiRules.max50]"
                  @click="unableToEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model.trim="shopInfo.shop_name_kana"
                  filled readonly
                  label="届出書記載の店舗名（かな）"
                  counter="50"
                  required
                  :rules="[valiRules.required, valiRules.max50]"
                  @click="unableToEdit"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- ビジネスタイプ -->
              <v-col cols="12" sm="6">
                <v-select
                  v-model="shopInfo.business_type_id"
                  filled
                  label="営業の業態"
                  :items="bizTypeArray"
                  item-value="id"
                  item-text="business_type"
                  item-color="primary"
                  readonly
                  required
                  :rules="[valiRules.required]"
                ></v-select>
              </v-col>
              <!-- グループ -->
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="shopInfo.group_name"
                  filled readonly
                  label="グループ名"
                  hint="グループ店舗間のアカウント連携に使われます"
                  persistent-hint
                  counter="30"
                  :rules="[valiRules.max30]"
                  @click="unableToEdit"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- 都道府県 -->
              <v-col cols="12" sm="6">
                <v-select
                  v-model="shopInfo.prefecture"
                  label="店舗所在の都道府県"
                  :items="prefectureArray"
                  item-value="prefecture"
                  item-text="prefecture"
                  item-color="primary"
                  hint="届出書記載の事務所所在地の都道府県名を選択してください"
                  required
                  :rules="[valiRules.required]"
                ></v-select>
              </v-col>
              <!-- エリア -->
              <v-col cols="12" sm="6">
                <v-combobox
                  v-model.trim="shopInfo.area"
                  label="HPに記載する営業エリア"
                  hint="リストに無い場合は手入力してください"
                  persistent-hint
                  :items="areaArray"
                  :return-object="false"
                  item-value="area"
                  item-text="area"
                  item-color="primary"
                  counter="20"
                  :loading ="laodingArea"
                  required
                  :rules="[valiRules.required, valiRules.max20]"
                ></v-combobox>
              </v-col>
            </v-row>

            <v-row>
              <!-- 住所 -->
              <v-col cols="12">
                <v-text-field
                  v-model.trim="shopInfo.address"
                  label="店舗型営業の際の住所"
                  hint="店舗受付けやサービスルーム等のお客様をご案内する住所を要する業態の店舗様は記載してください"
                  counter="100"
                  required
                  :rules="[valiRules.required, valiRules.max100]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- 電話 -->
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model.trim="shopInfo.phone"
                  label="電話番号"
                  counter="20"
                  required
                  :rules="[valiRules.required, valiRules.phone, valiRules.max20]"
                ></v-text-field>
              </v-col>
              <!-- オフィシャルURL -->
              <v-col cols="12" sm="8"
                class="mt-n1"
              >
                <v-text-field
                  v-model.trim="shopInfo.official_url"
                  filled dense readonly
                  label="オフィシャルHPのURL"
                  hint="管理アカウントの情報と結びついているので変更するとHPの情報が表示されなくなります"
                  counter="100"
                  required
                  :rules="[valiRules.required, valiRules.url, valiRules.max100]"
                  @click="unableToEdit"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- 開始時刻 -->
              <v-col cols="12" sm="4">
                <v-menu top
                  v-model="openPickerOpening"
                  :close-on-content-click="false"
                  :nudge-left="60"
                  transition="scroll-y-reverse-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="shopInfo.opening_hour"
                      label="営業開始時刻"
                      prepend-icon="mdi-clock-start"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="[valiRules.required]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="shopInfo.opening_hour"
                    format="24hr"
                    :allowed-minutes="m => m % 60 === 0"
                    header-color="primary"
                    color="primary"
                    @click:hour="openPickerOpening = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <!-- 終了時刻 -->
              <v-col cols="12" sm="4">
                <v-menu top
                  v-model="openPickerClosing"
                  :close-on-content-click="false"
                  :nudge-left="60"
                  transition="scroll-y-reverse-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="shopInfo.closing_hour"
                      label="営業終了時刻"
                      prepend-icon="mdi-clock-end"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="[valiRules.required]"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="shopInfo.closing_hour"
                    format="24hr"
                    :allowed-minutes="m => m % 60 === 0"
                    header-color="primary"
                    color="primary"
                    @click:hour="openPickerClosing = false"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>

            <!-- ボタン -->
            <v-row no-gutters justify="end">
              <v-btn
                depressed
                color="primary"
                @click="submitted()"
              >更新</v-btn>
            </v-row>

          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm"
      @modal-confirmed="$refs.modalConfirm.close()"
    >
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { API_ENDPOINT } from "@/literals.js"
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue"
import IconInfo from "@/components/_IconInfo.vue"
import ModalConfirm from '@/components/_ModalConfirm.vue'

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
    'modal-confirm': ModalConfirm
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      shopInfo: {},
      prefectureArray: [],
      areaArray: [],
      bizTypeArray: [],
      openPickerOpening: false,
      openPickerClosing: false,
      modalMessage: '',
      laodingArea: false,
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      systemApi: new ApiTool(API_ENDPOINT + '/', this.shopData),
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  watch: {
    'shopInfo.prefecture': function(now, old) {
      if (old) this.shopInfo.area = ''
      this.laodingArea = true

      const pref = this.prefectureArray.find( row => row.prefecture === now )
      this.systemApi.getReqSystemPublic('area/', { prefectureId: pref.id }).then( results => {
        this.areaArray = [...results]
        this.laodingArea = false
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },
  },

  created() {
    this.systemApi.setToken(this.serverToken)
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = 'データ取得中・・・'

      await Promise.all([
        this.systemApi.getReqSystemPublic('business-type/').then( results => this.bizTypeArray = [...results]),
        this.systemApi.getReqSystemPublic('prefecture/').then( results => this.prefectureArray = [...results]),
      ])

      this.shopInfo = {...this.shopData}
      this.loading = false
    },

    submitted() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const opH = Number(this.shopInfo.opening_hour.split(':')[0])
      const clH = Number(this.shopInfo.closing_hour.split(':')[0])

      //時刻バリデーション
      if (opH === clH) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningBizHourLessThan24H, open: true}}
        return
      }
      if ((clH > opH && (clH - opH) < 3) || (clH < opH && (opH - clH) > 21)) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningBizHourMoreThan3H, open: true}}
        return
      }

      //成功後にログインし直す確認モーダル
      this.modalMessage = `<p>店舗の基本情報を更新するため更新後にログインし直す必要がありますがよろしいですか？</p>`

      const modalHanddown = {
        buttonLabel: 'はい',
        yesCallback: this.update,
      }
      this.$refs.modalConfirm.open(modalHanddown)
    },

    unableToEdit() {
      this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningShopInfoUnableToEdit, open: true}}
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //更新
    //**************************************************
    update() {
      this.loading = true
      this.loadingMessage = 'データ更新中・・・'

      const apiPath = 'update/basic-info/'
      const formData = new FormData()
      // formData.append('shop_name', this.shopInfo.shop_name)
      // formData.append('shop_name_kana', this.shopInfo.shop_name_kana)
      // formData.append('group_name', this.shopInfo.group_name)
      // formData.append('is_licensed', true)
      // formData.append('business_type_id', this.shopInfo.business_type_id)
      formData.append('prefecture', this.shopInfo.prefecture)
      formData.append('area', this.shopInfo.area)
      formData.append('phone', this.shopInfo.phone)
      formData.append('address', this.shopInfo.address)
      formData.append('official_url', this.shopInfo.official_url || '')
      formData.append('opening_hour', this.shopInfo.opening_hour)
      formData.append('closing_hour', this.shopInfo.closing_hour)

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then(() => {
        this.$emit('reset')
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },
  }
}
</script>

<style scoped>
>>> .v-time-picker-title {
  justify-content: center;
}
</style>
